import { Grid, Slide } from '@mui/material';
import moment from 'moment';
import React from 'react';

import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';

function AdministratorLogSyliusList() {
  const [loadingContent, setLoadingContent] = React.useState(true);
  const [optionsIsSuccess] = React.useState([
    { value: 'all', label: 'Tous' },
    { value: 'true', label: 'Succès' },
    { value: 'false', label: 'Échec' },
  ]);
  const [optionsMethod] = React.useState([
    { value: 'all', label: 'Tous' },
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PATCH', label: 'PATCH' },
    { value: 'PUT', label: 'PUT' },
  ]);
  const moveColumns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'label',
      label: 'Libellé',
      options: { filter: true, sort: true },
    },
    {
      name: 'method',
      label: 'Méthode',
      options: { filter: true, sort: true },
    },
    {
      name: 'url',
      label: 'Url',
      options: { filter: true, sort: true },
    },
    {
      name: 'payload',
      label: 'Payload',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* Limit content */}
            <div
              style={{
                maxWidth: '350px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
              title={value} // Show popup on hover
            >
              {value}
            </div>
            <div style={{ display: 'flex' }}>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#007bff',
                }}
                onClick={() => showFullContent(value)}
              >
                Voir
              </button>

              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#007bff',
                }}
                onClick={() => copyToClipboard(value)}
              >
                Copier
              </button>
            </div>
          </div>
        ),
      },
    },
    {
      name: 'message',
      label: 'Message',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* Limit content */}
            <div
              style={{
                maxWidth: '350px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
              title={value} // Show popup on hover
            >
              {value}
            </div>
            <div style={{ display: 'flex' }}>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#007bff',
                }}
                onClick={() => showFullContent(value)}
              >
                Voir
              </button>

              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#007bff',
                }}
                onClick={() => copyToClipboard(value)}
              >
                Copier
              </button>
            </div>
          </div>
        ),
      },
    },
    {
      name: 'isSuccess',
      label: 'État',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
  ];

  const showFullContent = (content) => {
    alert(content);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Texte copié !');
      })
      .catch((_err) => {
        alert('Impossible de copier le texte. Veuillez réessayer.');
      });
  };

  // formSearch
  const [formSearch, setFormSearch] = React.useState({
    label: {
      name: 'label',
      label: 'Libellé',
      textHelper: 'Rechercher par libellés.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_sylius_list'] &&
        cacheTableServer['api_administrator_log_sylius_list'].label
          ? cacheTableServer['api_administrator_log_sylius_list'].label
          : '',
    },
    method: {
      name: 'method',
      label: 'Méthode',
      textHelper: 'Rechercher par méthodes.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_sylius_list'] &&
        cacheTableServer['api_administrator_log_sylius_list'].method
          ? cacheTableServer['api_administrator_log_sylius_list'].method
          : 'all',
      options: {},
    },
    url: {
      name: 'url',
      label: 'URL',
      textHelper: 'Rechercher par url.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_sylius_list'] &&
        cacheTableServer['api_administrator_log_sylius_list'].url
          ? cacheTableServer['api_administrator_log_sylius_list'].url
          : '',
    },
    message: {
      name: 'message',
      label: 'Message',
      textHelper: 'Rechercher par messages.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_sylius_list'] &&
        cacheTableServer['api_administrator_log_sylius_list'].message
          ? cacheTableServer['api_administrator_log_sylius_list'].message
          : '',
    },
    isSuccess: {
      name: 'isSuccess',
      label: 'État',
      textHelper: 'Rechercher par état.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_administrator_log_sylius_list'] &&
        cacheTableServer['api_administrator_log_sylius_list'].isSuccess
          ? cacheTableServer['api_administrator_log_sylius_list'].isSuccess
          : 'all',
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_administrator_log_sylius_list'] = 0;
    cacheTableServer['api_administrator_log_sylius_list'].page = 0;
    cacheTableServer['api_administrator_log_sylius_list'][index] = value;
    dispatch(TableReload('api_administrator_log_sylius_list'));
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    setLoadingContent(false);
  }, []);

  return (
    <ContentViewComponent
      loading={loadingContent}
      breadcrumbs={{
        title: 'Liste des Log - Sylius',
        context: 'Administrateur',
      }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  id={'label'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('label', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <SelectComponent
                  id={'method'}
                  options={optionsMethod}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('method', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  id={'url'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('url', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TextFieldComponent
                  id={'message'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('message', val)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <SelectComponent
                  id={'isSuccess'}
                  options={optionsIsSuccess}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('isSuccess', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_administrator_log_sylius_list'}
        title={'Log Sylius'}
        columns={moveColumns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            label: formSearch.label.value ? formSearch.label.value : '',
            method: datas.method === 'all' ? '' : datas.method,
            url: formSearch.url.value ? formSearch.url.value : '',
            message: formSearch.message.value ? formSearch.message.value : '',
            isSuccess: datas.isSuccess === 'all' ? '' : datas.isSuccess === 'true',
          };

          ApiHandler.get(
            {
              route: 'api_administrator_log_sylius_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].isSuccess = data[index].isSuccess ? (
                  <span style={{ color: '#28a745' }}>Succès</span>
                ) : (
                  <span style={{ color: '#982525' }}>Échec</span>
                );
                data[index].createdAt = moment(data[index].createdAt).format('lll');
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />

      <br />
      <br />
    </ContentViewComponent>
  );
}

export default AdministratorLogSyliusList;

/*
    UPDATE `request_async` SET `count_trials` = '0';
    UPDATE `request_async` SET `is_success` = null;
    UPDATE `request_async` SET `is_done` = '0';
*/
